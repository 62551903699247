/**
 * Konvertiert eine Byte-Anzahl in eine menschenlesbare Form (B, KB, MB, GB).
 * @param {number} bytes - Die Anzahl an Bytes, die umgerechnet werden soll.
 * @returns {string} - Eine Zeichenkette mit der umgerechneten Größe und der passenden Einheit.
 */
export function formatBytes(bytes) {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (isNaN(bytes) || bytes === null || bytes === 0) return '0 B';

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const n = bytes / 1024 ** i;

  /** @type {Intl.NumberFormatOptions} */
  const options = { maximumFractionDigits: 2 };
  const locales = navigator.languages;
  const formattedBytes = n.toLocaleString(locales, options);

  return `${formattedBytes} ${sizes[i]}`;
}

/**
 * Erstellt ein Thumbnail mit einer festen maximalen Breite und Höhe,
 * behält dabei das Seitenverhältnis bei.
 *
 * @param {File} imageFile - Das Bild, von dem das Thumbnail erstellt werden soll.
 * @param {number} maxWidth - Die maximale Breite des Thumbnails.
 * @param {number} maxHeight - Die maximale Höhe des Thumbnails.
 * @returns {Promise<HTMLCanvasElement>} - Ein Promise, das ein Canvas-Element mit dem Thumbnail zurückgibt.
 */
export function createThumbnail(imageFile, maxWidth, maxHeight) {
  if (!(imageFile instanceof File))
    return Promise.reject(new Error('imageFile is not an actual file!'));

  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(imageFile);

    img.onload = () => {
      const aspectRatio = img.width / img.height;

      let newWidth = maxWidth;
      let newHeight = maxHeight;

      if (img.width > img.height) {
        newHeight = maxWidth / aspectRatio;
      } else {
        newWidth = maxHeight * aspectRatio;
      }
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      resolve(canvas);
    };

    img.onerror = (err) => {
      reject(err);
    };

    img.src = url;
  });
}

export function dataURLToFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
