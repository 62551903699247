export function isHidden(element) {
  return (element.getAttribute('aria-hidden') ?? 'false') === 'true';
}

export function toggle(element) {
  if (isHidden(element)) {
    show(element);
  } else {
    hide(element);
  }
}

/**
 * @param {string | Element} element
 */
export function hide(element) {
  if ('string' === typeof element) element = document.querySelector(element);
  element.setAttribute('aria-hidden', 'true');
}

/**
 * @param {string | Element} element
 */
export function show(element) {
  if ('string' === typeof element) element = document.querySelector(element);
  element.setAttribute('aria-hidden', 'false');
}

/**
 * @param {string | Element[] | NodeListOf<Element>} elements
 */
export function multiHide(elements) {
  if ('string' === typeof elements)
    elements = document.querySelectorAll(elements);
  for (const element of elements) {
    element.setAttribute('aria-hidden', 'true');
  }
}

/**
 * @param {string | Element[] | NodeListOf<Element>} elements
 */
export function multiShow(elements) {
  if ('string' === typeof elements)
    elements = document.querySelectorAll(elements);
  for (const element of elements) {
    element.setAttribute('aria-hidden', 'false');
  }
}
