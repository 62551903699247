export const sourceIdView = document.querySelector('#sourceIdView');

export const generateBtn = document.querySelector('#generate');
export const qrcodeEl = document.querySelector('#qrcode');
export const idInInput = document.querySelector('#idin');
export const idOutInput = document.querySelector('#idout');
export const textInput = document.querySelector('#text');
export const nameInput = document.querySelector('#name');
export const fileBtn = document.querySelector('#file');
export const dirBtn = document.querySelector('#directory');
export const logoutBtn = document.querySelector('#logout');

export const connectInput = document.querySelector('#connectInput');
export const connectReset = document.querySelector('#connectReset');

export const scanBtn = document.querySelector('#scan');
export const scanEl = document.querySelector('#scanView');

export const selfieCamera = document.querySelector('#selfie');
export const environmentCamera = document.querySelector('#environment');

export const camContainer = document.getElementById('cam');
export const enableCamBtn = document.querySelector('#enablecam');

export const videoContainer = document.querySelector('#video-container');
export const videoEl = document.querySelector('#video');
videoEl.setAttribute('autoplay', '');
videoEl.setAttribute('muted', '');
videoEl.setAttribute('playsinline', '');

export const generatedIdInput = document.querySelector('#generatedIdInput');
export const generatedIdReset = document.querySelector('#generatedIdReset');

// TODO: cleanup
export const buttonPane = document.querySelector('#buttonPane');
export const showConnIdBtn = document.querySelector('#showConnIdBtn');
export const showConnectionId = document.querySelector('#showConnectionId');
export const connectToIdBtn = document.querySelector('#connectToIdBtn');
export const connectToId = document.querySelector('#connectToId');
export const remoteIdInput = document.querySelector('#remoteId');
export const connectBtn = document.querySelector('#connectBtn');
export const identity = document.querySelector('#identity');

export const chat = document.querySelector('#chat');
export const messages = document.querySelector('#messages');
export const chatinput = document.querySelector('#chatinput');
export const sendinput = document.querySelector('#send');
