/// <reference path="../types/alpinejs@3.14.1.d.ts" />
import Alpine from 'https://esm.sh/alpinejs@3.14.1';
import { sha256 } from '../core/crypto.js';
import { createThumbnail } from '../core/files.js';

/**
 * Alpine.js component to manage a dropzone for file uploads.
 *
 * @example
 * <div x-data="dropzone" id="dropzone">
 *   <input x-ref="input" type="file" name="files" id="dropzone-input" multiple />
 * </div>
 *
 * @function init - Initializes the component.
 * @function onFileAdded - Called when a file is dropped onto the component. It
 *   adds the file to the component's state and triggers the component to
 *   re-render.
 */
export default function initDropZone() {
  Alpine.data('dropzone', () => ({
    init() {
      this.$nextTick(() => {
        for (const type of ['drag', 'dragstart', 'dragend', 'dragenter']) {
          this.$root.addEventListener(type, (event) => event.preventDefault());
        }
        this.$root.ondragover = (event) => {
          event.preventDefault();
          this.$root.classList.add('dropzone-dragging');
        };
        this.$root.ondragleave = (event) => {
          event.preventDefault();
          this.$root.classList.remove('dropzone-dragging');
        };
        this.$root.ondrop = (event) => {
          event.preventDefault();
          this.onFileAdded(event);
        };
        this.$root.onclick = () => {
          this.$refs.input.click();
        };
        this.$refs.input.onchange = this.onFileAdded;
      });
    },

    async onFileAdded(event) {
      const files = event?.dataTransfer?.files ?? event?.target?.files;
      if (files && files.length > 0) {
        for (const file of files) {
          try {
            const hash = await sha256(file);
            const thumbnail = file.type.startsWith('image/')
              ? (await createThumbnail(file, 80, 80)).toDataURL()
              : null;
            Alpine.store('files').set(hash, {
              name: file.name,
              ext: file.name.split('.').pop() || '',
              type: file.type,
              size: file.size,
              currentSize: 0,
              count: 0,
              total: 1,
              thumbnail,
              hash,
              file,
            });
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
  }));

  Alpine.store('files', new Map());
}
