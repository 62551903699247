/// <reference path="../types/alpinejs@3.14.1.d.ts" />
import Alpine from 'https://esm.sh/alpinejs@3.14.1';

/**
 * A Alpine.js component to display a progress bar.
 *
 * @example
 * <div
 *   x-data="progressBar"
 *   x-effect="progressContent"
 *   class="progress-bar"
 *   data-hash="my-hash"
 *   data-slider-color="var(--fg-color)"
 *   data-content-color="var(--bg-color)"
 * ><div class="progress-slider" x-effect="progressSlider"></div></div>
 *
 * @property {string | null} hash - The hash of the download in the `downloadList` store
 * @property {number} value - The value of the progress bar
 * @property {number} total - The total value of the progress bar
 * @property {string | null} sliderColor - The color of the progress bar
 * @property {string | null} contentColor - The color of the content of the progress bar
 *
 * @function init - Initializes the component
 * @function progressContent - Updates the color of the content of the progress bar
 * @function valuePercent - Calculates the percentage of the progress bar
 * @function progressSlider - Updates the width and color of the progress bar
 */
export default function initProgressBar() {
  Alpine.data('progressBar', () => ({
    /** @type {string} */
    listName: '',
    /** @type {string | null} */
    hash: null,
    /** @type {number} */
    value: 0,
    /** @type {number} */
    total: 100,
    /** @type {string | null} */
    sliderColor: null,
    /** @type {string | null} */
    contentColor: null,

    init() {
      Alpine.effect(() => {
        if (this.hash) {
          const item = Alpine.store(this.listName).get(this.hash);
          if (item) {
            this.value = item.count;
            this.total = item.total;
          }
        }
      });
      this.$nextTick(() => {
        this.listName = this.$el.dataset.listName;
        this.hash = this.$el.dataset.hash;
        this.sliderColor = this.$el.dataset.sliderColor;
        this.contentColor = this.$el.dataset.contentColor;
      });
    },

    progressContent() {
      const { contentColor } = this.$data;
      const style = this.$el.style;
      if (contentColor) style.setProperty('background-color', contentColor);
    },

    valuePercent() {
      if (this.$data.value === 0) return '0%';
      if (this.$data.value === this.$data.total) return '100%';
      return `${(this.$data.value / (this.$data.total || 1)) * 100}%`;
    },

    progressSlider() {
      const style = this.$el.style;
      const valuePercent = this.valuePercent();
      style.setProperty('width', valuePercent);
      if (this.$data.sliderColor)
        style.setProperty('background-color', this.$data.sliderColor);
    },
  }));
}
