/**
 * @param {ArrayBuffer | Uint8Array | number[] | string} value
 * @returns {boolean}
 */
export function isBase64(value) {
  if ('string' === typeof value) {
    return /^[a-zA-Z0-9+/=]+$/.test(value);
  }

  value = Array.from(value);

  // Every character that is not in the pattern of [a-zA-Z0-9+/=]
  // indicates that it's not Base64.
  for (const n of value) {
    if (
      n !== 43 &&
      n !== 47 &&
      n !== 61 &&
      (n < 48 || n > 57) &&
      (n < 65 || n > 90) &&
      (n < 97 || n > 122)
    )
      return false;
  }

  return true;
}

export function isObject(o) {
  return Object.prototype.toString.call(o) === '[object Object]';
}

export function isPlainObject(o) {
  var ctor, prot;

  if (isObject(o) === false) return false;

  ctor = o.constructor;
  if (ctor === undefined) return true;

  prot = ctor.prototype;
  if (isObject(prot) === false) return false;

  if (Object.prototype.hasOwnProperty.call(prot, 'isPrototypeOf') === false) {
    return false;
  }

  return true;
}
