customElements.define(
  'progress-bar',
  class extends HTMLElement {
    static observedAttributes = [
      'total',
      'value',
      'color',
      'text-idle',
      'text-done',
      'text-run',
    ];

    _update() {
      if (!this.label || !this.bar) return;
      const value = parseInt(this.getAttribute('value') ?? '0', 10);
      const total = parseInt(this.getAttribute('total') ?? '100', 10);
      const percent = (value / total) * 100;
      const percentText = percent.toFixed(2) + '%';

      let text = '';
      if (percent === 0) {
        text =
          this.getAttribute('text-idle') ??
          '{value}/{total} proceeded ({percent})';
      } else if (percent === 100) {
        text = this.getAttribute('text-done') ?? 'Complete.';
      } else {
        text =
          this.getAttribute('text-run') ??
          'Proceeding {value}/{total} ({percent})';
      }

      text = text
        .replace('{value}', value)
        .replace('{total}', total)
        .replace('{percent}', percentText);

      this.label.textContent = text;
      this.bar.style.setProperty('width', percentText);
    }

    connectedCallback() {
      const bar = (this.bar = document.createElement('div'));
      bar.classList.add('bar');
      bar.style.setProperty('background-color', this.getAttribute('color'));
      const label = (this.label = document.createElement('div'));
      label.classList.add('label');
      this.appendChild(bar);
      this.appendChild(label);
      this._update();
    }

    disconnectedCallback() {
      if (this.bar) {
        this.bar.remove();
        this.bar = null;
      }
      if (this.label) {
        this.label.remove();
        this.label = null;
      }
    }

    attributeChangedCallback() {
      this._update();
    }
  },
);
