/// <reference path="./types/globals.d.ts" />
import log from './core/log.js';
import { sha256 } from './core/crypto.js';
import { createThumbnail } from './core/files.js';

const ignoreFilesStorage = JSON.parse(
  localStorage.getItem('ignore_files') ?? '[]',
);

if (ignoreFilesStorage.length === 0) {
  ignoreFilesStorage.push(...['.ds_store', 'thumbs.db', '$recycle.bin']);
}

export const ignoreFiles = new Set(ignoreFilesStorage);

// TODO: add ignore files, maybe something like gitignore

/**
 * @param {{ send(file: File | Blob | ArrayBuffer | string): void; receivedChunks: ReadableStream; sentChunk: ReadableStream }} transmitter
 * @param {FileEntry[]} files
 */
export function sendFiles(transmitter, files) {
  const hashset = new Set();
  for (const entry of files) {
    hashset.add(entry.hash);
  }
  for (const hash of hashset.values()) {
    if (fd.has(hash)) {
      transmitter.send(fd.get(hash));
      // await peer.send(fd.get(hash));
    }
  }
}

/**
 * @param {'files' | 'directory'} [type]
 * @param {FileList} [files]
 * @returns {Promise<FileEntry[]>}
 */
export async function createFileinfo(type = 'files', files = null) {
  try {
    if (files && type === 'files') {
      const entries = await Promise.all(
        Array.from(files).map((/** @type {File} */ file) =>
          (async () => {
            if (ignoreFiles.has(file.name.toLowerCase())) return null;

            const buf = await file.arrayBuffer();
            const hash = await sha256(buf);
            const thumbnail = file.type.startsWith('image/')
              ? (await createThumbnail(file, 80, 80)).toDataURL('image/webp')
              : null;
            if (!fd.has(hash)) fd.set(hash, buf);
            return {
              name: file.name,
              ext: file.name.split('.').pop() || '',
              hash,
              type: file.type,
              size: buf.byteLength,
              currentSize: 0,
              count: 0,
              total: 1,
              thumbnail,
            };
          })().catch((error) => log.error('process files', error, file)),
        ),
      );

      return entries.filter((file) => file);
    } else if (type === 'files') {
      const fileHandles = await globalThis.showOpenFilePicker({
        multiple: true,
      });

      const entries = await Promise.all(
        fileHandles.map((handle) =>
          (async () => {
            /** @type {File} */
            const file = await handle.getFile();
            if (ignoreFiles.has(file.name.toLowerCase())) return null;

            const buf = await file.arrayBuffer();
            const hash = await sha256(buf);
            if (!fd.has(hash)) fd.set(hash, buf);
            return {
              name: file.name,
              path: file.name,
              type: file.type,
              hash,
              size: buf.byteLength,
            };
          })().catch((error) => log.error('process handles', error, handle)),
        ),
      );

      return entries.filter((file) => file);
    } else {
      const directoryHandle = await globalThis.showDirectoryPicker();
      return await getAllFiles(directoryHandle);
    }
  } catch (error) {
    log.error(error);
  }

  return [];
}

/** @type {Map<string, ArrayBuffer>} */
export const fd = new Map();

/**
 * @param {FileSystemDirectoryHandle} directoryHandle
 * @param {string} [path]
 * @returns {Promise<FileEntry[]>}
 */
async function getAllFiles(directoryHandle, path = '') {
  const files = [];
  for await (const handle of directoryHandle.values()) {
    if (handle.kind === 'file') {
      /** @type {File} */
      const file = await handle.getFile();
      if (ignoreFiles.has(file.name.toLocaleLowerCase())) continue;

      const buf = await file.arrayBuffer();
      const hash = await sha256(buf);
      if (!fd.has(hash)) fd.set(hash, buf);
      files.push({
        name: handle.name,
        path: path + handle.name,
        type: file.type,
        hash,
        size: buf.byteLength,
      });
    } else if (handle.kind === 'directory') {
      const children = await getAllFiles(handle, path + handle.name + '/');
      files.push(...children.filter((x) => !x.path.endsWith('/')));
    }
  }

  return files;
}
