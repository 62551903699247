/// <reference path="../types/alpinejs@3.14.1.d.ts" />
/// <reference path="../types/globals.d.ts" />
import Alpine from 'https://esm.sh/alpinejs@3.14.1';
import { formatBytes } from '../core/files.js';

export default function initBytesDirective() {
  Alpine.directive('bytes', (el, { expression }) => {
    Alpine.effect(() => {
      const data = Alpine.evaluate(el, expression);
      el.textContent = formatBytes(parseInt(data, 10));
    });
  });
}
