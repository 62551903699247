function log(...args) {
  const date = new Date();
  console.log(date.toLocaleTimeString(), ...args);
}

log.info = function info(...args) {
  const date = new Date();
  console.info(date.toLocaleTimeString(), ...args);
};

log.warn = function warn(...args) {
  const date = new Date();
  console.warn(date.toLocaleTimeString(), ...args);
};

log.error = function error(...args) {
  const date = new Date();
  console.error(date.toLocaleTimeString(), ...args);
};

log.createInputStream = function (options = {}) {
  const queue = [];
  let controller;
  return new (class extends ReadableStream {
    constructor() {
      super({
        ...options,
        start(ctr) {
          controller = ctr;
          while (queue.length > 0) {
            controller.enqueue(queue.shift());
          }
        },
      });
    }

    end() {
      controller.close();
    }

    log(...args) {
      this._log('log', args);
    }

    info(...args) {
      this._log('info', args);
    }

    warn(...args) {
      this._log('warn', args);
    }

    error(...args) {
      this._log('error', args);
    }

    _log(type, args) {
      if (controller) {
        controller.enqueue({ type, args });
      } else {
        queue.push({ type, args });
      }
    }
  })();
};

log.createOutputStream = function (options = {}) {
  return new WritableStream({
    ...options,
    write(chunk) {
      if (!chunk) return;
      console[chunk.type](new Date().toLocaleTimeString(), ...chunk.args);
    },
  });
};

log.stream = function (logger = log) {
  return new TransformStream({
    transform(chunk, controller) {
      logger(chunk);
      controller.enqueue(chunk);
    },
  });
};

export default log;
