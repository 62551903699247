/// <reference path="../types/@scure_base@1.1.7.d.ts" />
import { base58 } from 'https://esm.sh/@scure/base@1.1.7';
import log from './log.js';

/**
 * @param {File | Blob | ArrayBuffer | BufferSource} arrayBuffer
 * @returns {Promise<string>}
 */
export async function sha256(data) {
  if (data instanceof File || data instanceof Blob)
    data = await data.arrayBuffer();
  data = new Uint8Array(data.buffer ?? data);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

/**
 * Creates a secret key for AES encrytion/decryption.
 * @returns {Promise<CryptoKey>}
 */
export function generateSecretKey() {
  return crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );
}

/**
 * Takes a CryptoKey and converts it into a base58 string
 * @param {CryptoKey} secretKey
 */
export async function encodeSecretKey(secretKey) {
  const exportedKey = await crypto.subtle.exportKey('raw', secretKey);
  return base58.encode(new Uint8Array(exportedKey));
}

/**
 * Takes a base58 string and converts it into a CryptoKey
 * @param {string | Uint8Array} key
 * @returns {Promise<CryptoKey>}
 */
export function decodeSecretKey(key) {
  const decodedKey = key instanceof Uint8Array ? key : base58.decode(key);
  return crypto.subtle.importKey('raw', decodedKey, { name: 'AES-GCM' }, true, [
    'encrypt',
    'decrypt',
  ]);
}

/**
 * @param {CryptoKey} secretKey
 * @param {File | Blob | ArrayBuffer | BufferSource | string} message
 * @param {number} [ivLength=12]
 * @returns {Promise<Uint8Array>}
 */
export async function encryptMessage(secretKey, message, ivLength = 12) {
  if (message instanceof File || message instanceof Blob)
    message = await message.arrayBuffer();

  if ('string' === typeof message) message = new TextEncoder().encode(message);

  const iv = crypto.getRandomValues(new Uint8Array(ivLength));
  const encrypted = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    secretKey,
    new Uint8Array(message),
  );

  const ciphertext = new Uint8Array(ivLength + encrypted.byteLength);
  ciphertext.set(iv, 0);
  ciphertext.set(new Uint8Array(encrypted), ivLength);
  return ciphertext;
}

/**
 * @param {CryptoKey} secretKey
 * @param {BufferSource | ArrayBuffer | Blob | File} ciphertext
 * @param {number} [ivLength=12]
 * @returns {Promise<ArrayBuffer>}
 */
export async function decryptMessage(secretKey, ciphertext, ivLength = 12) {
  if (ciphertext instanceof File || ciphertext instanceof Blob)
    ciphertext = await ciphertext.arrayBuffer();
  const buf = new Uint8Array(ciphertext);
  const iv = buf.slice(0, ivLength);
  const encrypted = buf.slice(ivLength);
  const decrypted = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    secretKey,
    encrypted,
  );
  return decrypted;
}

/**
 * @param {CryptoKey} secretKey
 * @param {string | BufferSource | Blob | File} message
 * @param {number} [ivLength=12]
 * @returns {Promise<string>}
 */
export async function encryptMessageB64(secretKey, message, ivLength) {
  if (message instanceof File || message instanceof Blob)
    message = await message.arrayBuffer();
  if ('string' === typeof message) {
    message = new TextEncoder().encode(message);
  }
  const ciphertext = await encryptMessage(secretKey, message, ivLength);
  const binary = ciphertext.reduce((s, b) => s + String.fromCharCode(b), '');
  const encryptedB64 = btoa(binary);
  return encryptedB64;
}

/**
 * @param {CryptoKey} secretKey
 * @param {string | BufferSource | Blob | File} encryptedB64
 * @param {number} [ivLength=12]
 * @param {boolean} [asBinary=false]
 * @returns {Promise<string | ArrayBuffer>}
 */
export async function decryptMessageB64(
  secretKey,
  encryptedB64,
  ivLength = 12,
  asBinary = false,
) {
  if (encryptedB64 instanceof File || encryptedB64 instanceof Blob)
    encryptedB64 = await encryptedB64.arrayBuffer();

  if (encryptedB64 instanceof ArrayBuffer || ArrayBuffer.isView(encryptedB64))
    encryptedB64 = new TextDecoder().decode(encryptedB64);

  const binary = atob(encryptedB64);
  const ciphertext = new Uint8Array(
    binary.split('').map((s) => s.charCodeAt(0)),
  );
  const decrypted = await decryptMessage(secretKey, ciphertext, ivLength);
  return asBinary ? decrypted : new TextDecoder().decode(decrypted);
}

/**
 * @param {string} decodedId
 */
export async function parseDecodedId(decodedId) {
  const [encodedKey, encodedNonce] = decodedId.split(':');
  const secretKey = await decodeSecretKey(encodedKey);
  const nonce = base58.decode(encodedNonce);
  const id = await getEncodedId(decodedId);
  return { secretKey, nonce, id };
}

/**
 * @param {string} decodedId
 */
export async function getEncodedId(decodedId) {
  const encoder = new TextEncoder();
  const encodedId = encoder.encode(decodedId);
  const hashedId = await crypto.subtle.digest('SHA-256', encodedId);
  return base58.encode(new Uint8Array(hashedId));
}

/**
 * @param {CryptoKey} secretKey
 * @param {Uint8Array} nonce
 */
export async function getDecodedId(secretKey, nonce) {
  const encodedKey = await encodeSecretKey(secretKey);
  const encodedNonce = base58.encode(nonce);
  return encodedKey + ':' + encodedNonce;
}

/**
 * Creates a nonce that will be used to generate a unique ID
 * for communication with the WebSocket server.
 * @returns {Uint8Array}
 */
export function createNonce() {
  return crypto.getRandomValues(new Uint8Array(8));
}

export function createEncryptionStream(
  secretKey,
  { ivlen = 12, useBase64 = false } = {},
  logger = null,
) {
  return new TransformStream({
    async transform(chunk, controller) {
      if (!chunk) return;
      try {
        let encrypted;
        if (useBase64) {
          encrypted = await encryptMessageB64(secretKey, chunk, ivlen);
        } else {
          encrypted = await encryptMessage(secretKey, chunk, ivlen);
        }
        controller.enqueue(encrypted);
      } catch (error) {
        if (logger) {
          logger.error(error);
        } else {
          log.error(error);
        }
      }
    },
  });
}

export function createDecryptionStream(
  secretKey,
  { ivlen = 12, useBase64 = false } = {},
  logger = null,
) {
  return new TransformStream({
    async transform(chunk, controller) {
      if (!chunk) return;
      try {
        let encrypted;
        if (useBase64) {
          encrypted = await decryptMessageB64(secretKey, chunk, ivlen, true);
        } else {
          encrypted = await decryptMessage(secretKey, chunk, ivlen);
        }
        controller.enqueue(encrypted);
      } catch (error) {
        if (logger) {
          logger.error(error);
        } else {
          log.error(error);
        }
      }
    },
  });
}
