/// <reference path="./types/alpinejs@3.14.1.d.ts" />
/// <reference path="./types/globals.d.ts" />

import './dom/progress-bar.js';
import './dialog.js';
// import './dropzone.js';
import {
  enableCamBtn,
  generateBtn,
  idInInput,
  idOutInput,
  nameInput,
} from './elements.js';
import * as domUtil from './dom/util.js';
import { newUser, state } from './state.js';
import { onTextInput } from './qrconnect.js';
import * as files from './files.js';
import { getDecodedId, parseDecodedId, sha256 } from './core/crypto.js';
import { cancelQrScanner, enableEnvironmentCamera } from './cam.js';
import { BUS } from './bus.js';
// import { fileDownloadLinks, filePreview } from './dropzone.js';
import { loadConfig } from './loadConfig.js';
import { closeAllDialogs } from './dialog.js';
import log from './core/log.js';
import { fileTransmitter, messageTransmitter, peerConnect } from './makro.js';
import { createRTCStream } from './network/webrtc.js';
import { berlinSignalServer } from './network/websocket.js';
import * as stream from './core/streams.js';
import { formatBytes } from './core/files.js';

import Alpine from 'https://esm.sh/alpinejs@3.14.1';
import initProgressBar from './alpine/progressBar.js';
import initDownloadList from './alpine/downloadList.js';
import initDropZone from './alpine/dropZone.js';
import initUploadList from './alpine/uploadList.js';
import initBytesDirective from './alpine/bytesDirective.js';

globalThis.process = globalThis.process ?? { env: { DEBUG: '*' } };

/** @type {RTCConfiguration} */
const config = {
  iceServers: [
    { urls: 'stun:stun.l.google.com:19302' },
    {
      urls: 'turn:yoink.bot:42643?transport=tcp',
      username: 'yoink',
      credential: 'bot',
    },
  ],
};

const announcements = new Map();

const remoteNameElements = document.querySelectorAll('.remote_name');
const successFeedbacks = document.querySelectorAll('.success');
const connectorTools = document.querySelectorAll('.connector');
const cameraContainer = document.getElementById('video-container');
const qrcodeContainer = document.getElementById('qrcode-container');
const openGenerateDialog = document.getElementById('openGenerateDialog');
const openConnectDialog = document.getElementById('openConnectDialog');

// setup
globalThis.filetransmitter = null;
globalThis.sendMessage = null;
const { WEBSOCKET_URL } = await loadConfig();
await newUser();

document.addEventListener('alpine:init', () => {
  // directives
  initBytesDirective();

  // data
  initDropZone();
  initProgressBar();
  initDownloadList();
  initUploadList();

  // reactive
  Alpine.effect(() => {
    const fileEntries = Array.from(Alpine.store('files').values()).map(
      (x) => x.file,
    );
    if (globalThis.sendMessage && fileEntries.length > 0) {
      BUS.dispatchEvent(
        new CustomEvent('offer:fileinfo', {
          detail: fileEntries,
        }),
      );
    }
  });
});

function connectionSuccessFeedback() {
  for (const el of remoteNameElements) {
    el.textContent = state.your.name;
  }
  for (const el of successFeedbacks) {
    domUtil.show(el);
  }
  for (const el of connectorTools) {
    domUtil.hide(el);
  }

  const fileEntries = Array.from(Alpine.store('files').values());
  if (fileEntries.length > 0) {
    BUS.dispatchEvent(
      new CustomEvent('offer:fileinfo', {
        detail: fileEntries.map((x) => x.file),
      }),
    );
  }

  setTimeout(() => closeAllDialogs(), 2500);
}

async function connectAfterReceiveId(value, enableScan = false) {
  try {
    const { id, secretKey } = await parseDecodedId(value);

    document.body.classList.remove('source');
    document.body.classList.add('target');

    if (enableScan) {
      domUtil.hide(enableCamBtn);
      domUtil.show(cameraContainer);
    }
    domUtil.hide(openConnectDialog);
    domUtil.hide(openGenerateDialog);

    state.secretKey = secretKey;
    state.your.id = id;

    createPeerConnection('initiator');
  } catch (error) {
    log.error(error);
  }
}

async function connectAfterDistributeId() {
  document.body.classList.add('source');
  document.body.classList.remove('target');

  domUtil.hide(generateBtn);
  domUtil.show(qrcodeContainer);
  domUtil.hide(openConnectDialog);
  domUtil.hide(openGenerateDialog);

  idOutInput.value = await getDecodedId(state.secretKey, state.nonce);
  onTextInput();

  createPeerConnection('participant');
}

async function createPeerConnection(
  /** @type {'initiator' | 'participant'} */ role,
) {
  const peer = createRTCStream(role, state, {
    dataChannels: ['metadata', 'chunk', 'message'],
    peerConfig: config,
  });
  const key = state.secretKey;

  peerConnect(key, peer, berlinSignalServer(WEBSOCKET_URL, peer, state));
  peer.getStateReader().pipeTo(stream.writer(handlePeerState));

  globalThis.filetransmitter = fileTransmitter(key, peer, onFile);
  globalThis.sendMessage = messageTransmitter(key, peer, onMessage);

  globalThis.filetransmitter.receivedChunks().pipeTo(
    stream.writer(({ hash, total, count }) => {
      const file = Alpine.store('downloadList').get(hash);
      if (file) {
        file.count = count;
        file.total = total;
        if (count === total) {
          file.currentSize = file.size;
        } else {
          file.currentSize = count * 16384;
        }
        log(
          `received ${count}/${total} with ${file.currentSize} of ${file.size} bytes`,
        );
      }
    }),
  );
  globalThis.filetransmitter.sentChunks().pipeTo(
    stream.writer(({ hash, total, count }) => {
      const file = Alpine.store('files').get(hash);
      if (file) {
        file.count = count;
        file.total = total;
        if (count === total) {
          file.currentSize = file.size;
        } else {
          file.currentSize = count * 16384;
        }
        log(
          `sent ${count}/${total} with ${file.currentSize} of ${file.size} bytes`,
        );
      }
    }),
  );
}

function handlePeerState({ type, state }) {
  if (type !== 'connectionState') return;
  switch (state) {
    case 'new':
    case 'connecting':
      domUtil.hide('#receive-id-init');
      domUtil.show('#receive-id-connecting');
      break;
    case 'connected':
      domUtil.hide('#receive-id-connecting');
      domUtil.show('#receive-id-success');
      connectionSuccessFeedback();
      break;
    case 'closed':
    case 'disconnected':
    case 'failed':
      domUtil.show('#receive-id-init');
      domUtil.hide('#receive-id-connecting');
      domUtil.hide('#receive-id-success');
      break;
  }
}

function reconstructFile(chunk, hash) {
  const entry = Alpine.store('files').get(hash);
  if (!entry) return false;

  const blob = new Blob([chunk], {
    mimeType: entry.type,
  });
  const file = new File([blob], entry.name, { type: entry.type });
  entry.file = file;
  return true;
}

async function onFile(chunk) {
  const hash = await sha256(chunk);
  files.fd.set(hash, chunk);
  console.log('onFile', hash);

  handleBigMessage(chunk, hash);
  if (reconstructFile(chunk, hash)) {
    log('received file', formatBytes(chunk.byteLength));
    // fileDownloadLinks(fileEntries.filter((x) => x.file).map((x) => x.file));
  }
}

async function handleBigMessage(file, hash) {
  if (file && announcements.has(hash)) {
    const message = JSON.parse(new TextDecoder().decode(file));
    onMessage(message);
    files.fd.delete(hash);
    announcements.delete(hash);
    return true;
  }
  return false;
}

function onMessage(detail) {
  switch (detail.type) {
    case 'bigmessage':
      if (!announcements.has(detail.hash)) {
        log('received bigmessage', formatBytes(detail.size));
        announcements.set(detail.hash, detail);
      }
      handleBigMessage(files.fd.get(detail.hash), detail.hash);
      break;
    case 'fileinfo':
      {
        log('receive offer:fileinfo');
        for (const file of detail.files) {
          if (!Alpine.store('downloadList').has(file.hash)) {
            Alpine.store('downloadList').set(file.hash, file);
          }
        }
      }
      break;
    case 'request:files':
      {
        let uploadFiles = [];
        if (detail.files === 'all') {
          uploadFiles = Array.from(Alpine.store('files').values());
        } else {
          for (const hash of detail.files) {
            const entry = Alpine.store('files').get(hash);
            if (entry) uploadFiles.push(entry);
          }
        }
        if (detail.reload) {
          for (const file of uploadFiles) {
            file.count = 0;
            file.currentSize = 0;
          }
        }
        log.info('will send files', uploadFiles);
        files.sendFiles(globalThis.filetransmitter, uploadFiles);
      }
      break;
  }
}

generateBtn.onclick = connectAfterDistributeId;

idOutInput.onclick = () => {
  const end = idOutInput.value.length;
  idOutInput.setSelectionRange(0, end);
};

nameInput.value = state.my.name;
nameInput.oninput = () => {
  if (!state.my.id) {
    state.my = { id: null, name: null };
  }
  state.my.name = nameInput.value.trim();
};

idInInput.onpaste = async (event) => {
  if (event?.type === 'paste') {
    const value = await navigator.clipboard.readText();
    await connectAfterReceiveId(value, false);
  }
};

enableCamBtn.onclick = () => {
  domUtil.show(cameraContainer);
  domUtil.hide(enableCamBtn);
  enableEnvironmentCamera();
};

BUS.addEventListener('error', ({ error }) => log.error(error));

BUS.addEventListener('cam:scanned', ({ detail }) =>
  connectAfterReceiveId(detail, true),
);
BUS.addEventListener('dialog:close', () => cancelQrScanner());

BUS.addEventListener('request:files', ({ detail: { files, reload } }) => {
  log('request:files', files);
  if (globalThis.sendMessage) {
    globalThis.sendMessage({
      type: 'request:files',
      files,
      reload,
    });
  }
});

BUS.addEventListener(
  'offer:fileinfo',
  async (/** @type {{detail: FileList}} */ { detail }) => {
    log.warn('new fileinfos', detail);
    const fileEntries = await files.createFileinfo('files', detail);
    if (!fileEntries?.length) return;

    if (globalThis.sendMessage) {
      const fileinfo = {
        type: 'fileinfo',
        message: 'Dateien verfügbar!',
        files: fileEntries,
      };

      const data = JSON.stringify(fileinfo);
      const file = new File(
        [new Blob([data], { type: 'application/json' })],
        crypto.randomUUID(),
        {
          type: 'application/json',
        },
      );

      const hash = await sha256(file);
      console.log('fileinfo size', fileinfo, formatBytes(file.size));

      globalThis.sendMessage({
        type: 'bigmessage',
        hash,
        size: file.size,
      });
      globalThis.filetransmitter.send(file);
    }
  },
);

Alpine.start();
