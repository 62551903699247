/// <reference path="./types/unique-names-generator@4.7.1.d.ts" />
/// <reference path="./types/globals.d.ts" />
import {
  adjectives,
  animals,
  uniqueNamesGenerator,
} from 'https://esm.sh/unique-names-generator@4.7.1';
import {
  createNonce,
  generateSecretKey,
  getDecodedId,
  getEncodedId,
} from './core/crypto.js';

export const state = {
  secretKey: null,
  nonce: null,
  my: { id: null, name: null },
  your: { id: null, name: null },
  facingMode: 'environment',
  waitForBarcode: null,
};

/**
 * @param {string} [name]
 * @param {CryptoKey} [secretKey]
 * @param {Uint8Array} [nonce]
 * @param {State} [localState]
 * @returns {Promise<State>}
 */
export async function newUser(name, secretKey, nonce, localState = state) {
  resetState(false, localState);
  localState.secretKey = secretKey ?? (await generateSecretKey());
  localState.nonce = nonce ?? createNonce();

  const decodedId = await getDecodedId(localState.secretKey, localState.nonce);
  localState.my.id = await getEncodedId(decodedId);
  localState.my.name =
    name ??
    uniqueNamesGenerator({
      dictionaries: [adjectives, animals],
      length: 2,
      separator: '',
      style: 'capital',
    });
  return localState;
}
/**
 * @param {State} [localState]
 * @returns {Promise<State>}
 */
export async function rerollUserSecret(localState = state) {
  localState.secretKey = await generateSecretKey();
  localState.nonce = createNonce();
  const decodedId = await getDecodedId(localState.secretKey, localState.nonce);
  localState.my.id = await getEncodedId(decodedId);
  return localState;
}

/**
 * @param {State} localState
 * @returns {State}
 */
export async function rerollUserName(localState) {
  localState.my.name =
    name ??
    uniqueNamesGenerator({
      dictionaries: [adjectives, animals],
      length: 2,
      separator: '',
      style: 'capital',
    });
  return localState;
}

export function addUserState(id, name) {
  state.your = { id, name };
}

/**
 * @param {boolean} [keepName]
 * @param {State} [localState]
 * @returns {State}
 */
export function resetState(keepName = true, localState = state) {
  localState.secretKey = null;
  localState.nonce = null;
  localState.my = {
    id: null,
    name: keepName ? localState.my.name : null,
  };
  localState.your = { id: null, name: null };
  localState.facingMode = 'environment';
  localState.waitForBarcode = null;
  return localState;
}
