import {
  decryptMessage,
  decryptMessageB64,
  encryptMessage,
  encryptMessageB64,
  sha256,
} from './crypto.js';
import log from './log.js';
import { isPlainObject } from './util.js';

/**
 * @typedef FileInfo
 * @prop {string} filehash
 * @prop {number} size
 * @prop {number} update
 * @prop {number} totalChunks
 * @prop {number} foundChunks
 * @prop {Map<string, FileChunkInfo>} chunks
 */

/**
 * @typedef ChunkMetadata
 * @prop {string} type
 * @prop {string} filehash
 * @prop {number} size
 * @prop {number} totalChunks
 * @prop {string} hash
 * @prop {number} index
 */

/**
 * @typedef FileChunkInfo
 * @prop {number} index
 * @prop {string} hash
 * @prop {any} data
 */

/**
 * @param {{ chunkSize: number; mapping: Record<'metadata' | 'chunk', string> }} param0
 */
export function toChunks({ chunkSize = 16384, mapping = {} } = {}) {
  mapping.chunk ??= 'chunk';
  mapping.metadata ??= 'metadata';

  const buffer = new Map();

  /** @type {Map<ReadableStream, (hash: string, total: number, count: number) => void} */
  const counterStreams = new Map();
  return {
    get transform() {
      return new TransformStream({
        async transform(file, controller) {
          let fileinfo = {};
          if (isPlainObject(file)) {
            fileinfo = file[mapping.metadata];
            file = file[mapping.chunk];
          }

          const total = Math.ceil(file.byteLength / chunkSize);
          const filehash = await sha256(file);
          buffer.set(filehash, { time: Date.now(), chunks: new Map() });

          for (let i = 0; i < total; i++) {
            const start = i * chunkSize;
            const end = Math.min(file.byteLength, start + chunkSize);
            const chunk = file.slice(start, end);
            const hash = await sha256(chunk);
            buffer.get(filehash).chunks.set(hash, chunk);

            /** @type {ChunkMetadata} */
            const metadata = {
              type: 'chunk',
              decryptedHash: fileinfo.decryptedHash,
              filehash,
              hash,
              index: i,
              size: chunk.byteLength,
              totalChunks: total,
            };

            for (const count of counterStreams.values()) {
              count(metadata.decryptedHash ?? filehash, total, i + 1);
            }

            controller.enqueue({
              [mapping.metadata]: metadata,
              [mapping.chunk]: chunk,
            });
          }
        },
      });
    },

    getChunkCounter() {
      /** @type {ReadableStreamDefaultController} */
      let controller;
      const stream = new ReadableStream({
        start(ctr) {
          controller = ctr;
        },
        cancel(reason) {
          counterStreams.delete(stream);
          if (reason) {
            log.error(reason);
          }
        },
      });

      counterStreams.set(stream, function count(hash, total, count) {
        try {
          controller.enqueue({ hash, total, count });
        } catch (error) {
          controller.error(error);
        }
      });
      return stream;
    },
  };
}

/**
 * @param {{mapping: Record<'metadata' | 'chunk', string>}} param0
 */
export function fromChunks({ mapping = {} } = {}) {
  mapping.chunk ??= 'chunk';
  mapping.metadata ??= 'metadata';

  /** @type {Map<string, any} */
  const indeterminedBuffer = new Map();

  /** @type {Map<ReadableStream, (hash: string, total: number, count: number) => void} */
  const counterStreams = new Map();

  /** @type {Map<string, FileInfo>} */
  const buffer = new Map();

  /**
   * @param {string} hash
   * @param {any} chunk
   * @param {TransformStreamDefaultController} controller
   * @returns {Promise<boolean>}
   */
  async function rebuildFile(hash, chunk, controller) {
    const now = Date.now();
    let found = false;
    for (const info of buffer.values()) {
      const chunkInfo = info.chunks.get(hash);
      if (chunkInfo && chunkInfo.data === null) {
        chunkInfo.data = chunk;
        info.foundChunks++;
        info.update = now;
        found = true;

        for (const count of counterStreams.values()) {
          count(
            info.decryptedHash ?? info.filehash,
            info.totalChunks,
            info.foundChunks,
          );
        }
      }

      if (info.foundChunks === info.totalChunks) {
        buffer.delete(info.filehash);

        const chunks = Array.from(info.chunks.values());
        chunks.sort((a, b) => a.index - b.index);

        const blob = new Blob(chunks.map((x) => x.data));
        const ab = await blob.arrayBuffer();
        controller.enqueue(ab);
      }
    }

    return found;
  }

  return {
    get transform() {
      return new TransformStream({
        async transform(data, controller) {
          /** @type {ChunkMetadata} */
          const metadata = data[mapping.metadata];
          if (metadata) {
            const store = buffer.get(metadata.filehash);
            if (store) {
              if (!store.chunks.has(metadata.hash)) {
                store.chunks.set(metadata.hash, {
                  data: null,
                  hash: metadata.hash,
                  index: metadata.index,
                });
              }
              store.decryptedHash ??= metadata.decryptedHash;
              store.filehash ??= metadata.filehash;
              store.size ??= metadata.size;
              store.totalChunks ??= metadata.totalChunks;
            } else {
              buffer.set(metadata.filehash, {
                chunks: new Map([
                  [
                    metadata.hash,
                    {
                      data: null,
                      hash: metadata.hash,
                      index: metadata.index,
                    },
                  ],
                ]),
                decryptedhash: metadata.decryptedHash,
                filehash: metadata.filehash,
                foundChunks: 0,
                size: metadata.size,
                totalChunks: metadata.totalChunks,
              });
            }

            for (const [hash, data] of indeterminedBuffer.entries()) {
              const found = await rebuildFile(hash, data, controller);
              if (found === true) {
                indeterminedBuffer.delete(hash);
              }
            }
          }

          const chunk = data[mapping.chunk];
          if (chunk) {
            const chunkHash = await sha256(chunk);
            const found = await rebuildFile(chunkHash, chunk, controller);
            if (found === false) {
              indeterminedBuffer.set(chunkHash, chunk);
            }
          }
        },
      });
    },

    getChunkCounter() {
      /** @type {ReadableStreamDefaultController} */
      let controller;
      const stream = new ReadableStream({
        start(ctr) {
          controller = ctr;
        },
        cancel(reason) {
          counterStreams.delete(stream);
          if (reason) {
            log.error(reason);
          }
        },
      });

      counterStreams.set(stream, function count(hash, total, count) {
        try {
          controller.enqueue({ hash, total, count });
        } catch (error) {
          controller.error(error);
        }
      });
      return stream;
    },
  };
}

export function shuffle() {
  const chunks = [];

  function shuffleArray(array) {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  return new TransformStream({
    flush(controller) {
      for (const data of shuffleArray(chunks)) {
        controller.enqueue(data);
      }
    },
    transform(chunk) {
      chunks.push(chunk);
    },
  });
}

export function reader(
  /** @type {(controller: ReadableStreamDefaultController) => void | Promise<void>} */ start,
  closeAfter = false,
) {
  return new ReadableStream({
    async start(controller) {
      const runner = start(controller);
      if (runner instanceof Promise) {
        await runner;
      }
      if (closeAfter) {
        controller.close();
      }
    },
  });
}

export function writer(
  /** @type {(chunk: any, controller: WritableStreamDefaultController)} */ write,
) {
  return new WritableStream({ write });
}

export function through(
  /** @type {(chunk: any, controller: TransformStreamDefaultController) => void} */ transform,
) {
  return new TransformStream({
    transform,
  });
}

/**
 * @param {(chunk: any, index: number) => any} add
 */
export function addOrderNumber(add) {
  let index = 0;
  return new TransformStream({
    transform(chunk, controller) {
      controller.enqueue(add(chunk, index++));
    },
  });
}

/**
 * @param {<T>(chunk: T) => {index: number; data: T}} parse
 * @param {'ascending' | 'descending'} [dir]
 */
export function orderBy(parse, dir = 'ascending') {
  let queue = [];
  let timeout;
  return new TransformStream({
    transform(chunk, controller) {
      queue.push(chunk);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        queue = queue.map(parse);
        if (dir === 'ascending') queue.sort((a, b) => a.index - b.index);
        else queue.sort((a, b) => b.index - a.index);
        while (queue.length) {
          controller.enqueue(queue.shift().data);
        }
      }, 100);
    },
  });
}

/**
 * @returns {TransformStream}
 */
export function encodeJSON() {
  return new TransformStream({
    transform(chunk, controller) {
      try {
        controller.enqueue(JSON.stringify(chunk));
      } catch (error) {
        log.error(error);
      }
    },
  });
}

/**
 * @returns {TransformStream}
 */
export function decodeJSON() {
  return new TransformStream({
    transform(chunk, controller) {
      if ('string' !== typeof chunk) chunk = new TextDecoder().decode(chunk);
      try {
        controller.enqueue(JSON.parse(chunk));
      } catch (error) {
        log.error(error);
      }
    },
  });
}

/**
 * @param {CryptoKey} secretKey
 * @param {{ivlen: number; base64Enabled: boolean}}
 * @returns {TransformStream}
 */
export function encrypt(secretKey, { ivlen = 12, base64Enabled = false } = {}) {
  return new TransformStream({
    async transform(chunk, controller) {
      if (!chunk) return;
      try {
        const encrypted = base64Enabled
          ? await encryptMessageB64(secretKey, chunk, ivlen)
          : await encryptMessage(secretKey, chunk, ivlen);
        controller.enqueue(encrypted);
      } catch (error) {
        log.error(error);
      }
    },
  });
}

/**
 * @param {CryptoKey} secretKey
 * @param {{ivlen: number; base64Enabled: boolean}}
 * @returns {TransformStream}
 */
export function decrypt(secretKey, { ivlen = 12, base64Enabled = false } = {}) {
  return new TransformStream({
    async transform(chunk, controller) {
      if (!chunk) return;
      try {
        const decrypted = base64Enabled
          ? await decryptMessageB64(secretKey, chunk, ivlen, false)
          : await decryptMessage(secretKey, chunk, ivlen);
        controller.enqueue(decrypted);
      } catch (error) {
        log.error(error);
      }
    },
  });
}

/**
 * @param {CryptoKey} secretKey
 * @param {{ivlen: number; props: string[]; base64Enabled: boolean}}
 * @returns {TransformStream}
 */
export function encryptJSON(
  secretKey,
  { props = [], ivlen = 12, base64Enabled = false },
) {
  return new TransformStream({
    async transform(chunk, controller) {
      try {
        if (props.length > 0) {
          for (const prop of props) {
            if (chunk[prop]) {
              const message = isPlainObject(chunk[prop])
                ? JSON.stringify(chunk[prop])
                : chunk[prop];
              chunk[prop] = base64Enabled
                ? await encryptMessageB64(secretKey, message, ivlen)
                : await encryptMessage(secretKey, message, ivlen);
            }
          }
        } else {
          const message = isPlainObject(chunk) ? JSON.stringify(chunk) : chunk;
          chunk = base64Enabled
            ? await encryptMessageB64(secretKey, message, ivlen)
            : await encryptMessage(secretKey, message, ivlen);
        }
        controller.enqueue(chunk);
      } catch (error) {
        log.error(error);
      }
    },
  });
}

/**
 * @param {CryptoKey} secretKey
 * @param {{ivlen: number; props: string[]; base64Enabled: boolean}}
 * @returns {TransformStream}
 */
export function decryptJSON(
  secretKey,
  { props = [], ivlen = 12, base64Enabled = false },
) {
  return new TransformStream({
    async transform(chunk, controller) {
      try {
        if (props.length > 0) {
          for (const prop of props) {
            if (chunk[prop]) {
              const decrypted = base64Enabled
                ? await decryptMessageB64(secretKey, chunk[prop], ivlen, false)
                : await decryptMessage(secretKey, chunk[prop], ivlen);
              chunk[prop] = JSON.parse(new TextDecoder().decode(decrypted));
            }
          }
        } else {
          const decrypted = base64Enabled
            ? await decryptMessageB64(secretKey, chunk, ivlen, false)
            : await decryptMessage(secretKey, chunk, ivlen);
          chunk = JSON.parse(new TextDecoder().decode(decrypted));
        }
        controller.enqueue(chunk);
      } catch (error) {
        log.error(error);
      }
    },
  });
}

/**
 * @typedef SignalIdFeedback
 * @prop {'id'} type
 * @prop {'approved' | 'rejected'} status
 */

/**
 * @typedef {string} SignalInput
 */

/**
 * @typedef {ArrayBuffer | Uint8Array | string} SignalOutputData
 */

/**
 * @typedef {SignalIdFeedback | SignalOutputData} SignalOutput
 */

/**
 * @typedef {TransformStream<SignalInput, SignalOutput>} SignalTransferProtocol
 */

/**
 * @returns {SignalTransferProtocol}
 */
export function createSignalTransferProtocol(
  /** @type {Transformer<SignalInput, SignalOutput>} */ options,
) {
  return new TransformStream(options);
}
