import log from '../core/log.js';
import { isPlainObject } from '../core/util.js';

/**
 * @param {'initiator' | 'participant'} role
 * @param {import('../state.js').State} state
 * @param {{
 *           peerConfig?: RTCConfiguration;
 *           offerOptions?: RTCOfferOptions;
 *           answerOptions?: RTCAnswerOptions;
 *           dataChannels?: { [label: string]: RTCDataChannelInit } | string[];
 *        }}
 */
export function createRTCStream(
  role,
  state,
  { peerConfig, offerOptions, answerOptions, dataChannels = {} } = {},
) {
  const lowThreshold = 32768;

  /** @type {Map<RTCDataChannel, any[]>} */
  const bufferQueue = new Map();

  const pc = new RTCPeerConnection(peerConfig);

  /** @type {Map<string, {channel:RTCDataChannel; initialized: boolean}>} */
  const channels = new Map();

  /** @type {Map<string, any[]>} */
  const messageQueue = new Map();

  /** @type {ReadableStreamDefaultController} */
  let connReaderCtr;

  const events = new EventTarget();

  initiateConnection();

  async function createOffer() {
    const offer = await pc.createOffer(offerOptions);
    await pc.setLocalDescription(offer);
    connReaderCtr.enqueue({
      from: state.my,
      data: {
        type: 'offer',
        offer: pc.localDescription.toJSON(),
      },
    });
  }

  async function createAnswer() {
    const answer = await pc.createAnswer(answerOptions);
    await pc.setLocalDescription(answer);
    // TODO: make sure reader exists! Waiting and maybe throw error
    connReaderCtr.enqueue({
      from: state.my,
      data: {
        type: 'answer',
        answer: pc.localDescription.toJSON(),
      },
    });
  }

  function initiateConnection() {
    if (role === 'initiator') {
      dataChannels = Array.isArray(dataChannels)
        ? new Map(dataChannels.map((label) => [label])).entries()
        : Object.entries(dataChannels);
      for (const [label, init] of dataChannels) {
        const channel = pc.createDataChannel(label, init);
        channels.set(label, { channel, initialized: false });

        function onHandshake({ data }) {
          const { initialized } = channels.get(label) ?? {};
          if (!initialized && data === 'hello') {
            channels.set(label, { channel, initialized: true });
            events.dispatchEvent(
              new CustomEvent('channelcreate', { detail: channel }),
            );
            channel.removeEventListener('message', onHandshake);
          }
        }
        channel.onmessage = onHandshake;
      }
    } else {
      pc.ondatachannel = (event) => {
        const channel = event.channel;
        channels.set(channel.label, { channel, initialized: false });
        channel.onopen = () => {
          channels.get(channel.label).initialized = true;
          channel.send('hello');
          events.dispatchEvent(
            new CustomEvent('channelcreate', { detail: channel }),
          );
        };
      };
    }

    pc.onicecandidate = (event) => {
      log.info('ice-candidate', event.candidate);
      if (event.candidate) {
        connReaderCtr.enqueue({
          from: state.my,
          data: { type: 'candidate', candidate: event.candidate },
        });
      }
    };

    pc.oniceconnectionstatechange = () => {
      log.info('iceGatheringState', pc.iceConnectionState);
    };

    pc.onicecandidateerror = (event) => {
      const error = new Error(event.errorText);
      error.code = event.errorCode;
      log.error(error, event);
      log.warn('iceConnectionState', pc.iceConnectionState);
      log.warn('iceGatheringState', pc.iceGatheringState);
    };

    pc.onicegatheringstatechange = () => {
      log.info('iceGatheringState', pc.iceGatheringState);
    };

    pc.onsignalingstatechange = () => {
      log.info('signalingState', pc.signalingState);
    };

    pc.onconnectionstatechange = () => {
      switch (pc.connectionState) {
        case 'new':
        case 'connecting':
          log(role, 'peer is connecting...');
          break;
        case 'connected':
          log(role, 'peer connected!');
          connReaderCtr.close();
          break;
        case 'disconnected':
          log(role, 'peer disconnected!');
          break;
        case 'closed':
          log(role, 'peer connection closed!');
          break;
        case 'failed':
          log.error(role, 'peer connection errored!');
          break;
      }
    };
  }

  function addInitQueueItem(channelId, message) {
    log.warn(`channel ${channelId} is putting message in queue...`);
    const queue = messageQueue.get(channelId);
    if (queue) {
      queue.push(message);
    } else {
      messageQueue.set(channelId, [message]);
    }
  }

  function handleInitChannel(/** @type {RTCDataChannel} */ channel) {
    const label = channel.label;
    const { initialized } = channels.get(label) ?? { initialized: false };
    if (initialized === false) return;

    log(
      role,
      `reading message queue from channel ${channel.label} mit ready state ${channel.readyState}`,
    );
    const queue = messageQueue.get(channel.label);

    channel.onbufferedamountlow = () => {
      const files = bufferQueue.get(channel) ?? [];
      while (files.length > 0) {
        bufferedSend(channel, files.shift());
      }
    };

    if (initialized && queue && channel.readyState === 'open') {
      log(role, `send queued messages from ${channel.label}`);
      for (const message of queue) {
        channel.bufferedAmountLowThreshold = lowThreshold;
        bufferedSend(channel, message);
      }
      messageQueue.delete(channel.label);
    }
  }

  function bufferedSend(channel, message) {
    try {
      if (channel.bufferedAmount < 65536) {
        channel.send(message);
      } else {
        setTimeout(() => bufferedSend(channel, message), 100);
      }
    } catch (error) {
      log.warn(error);
      const messages = bufferQueue.get(channel) ?? [message];
      bufferQueue.set(channel, messages);
    }
  }

  function send(label, message) {
    const { channel, initialized } = channels.get(label) ?? {
      channel: null,
      initialized: false,
    };
    if (channel && initialized) {
      switch (channel.readyState) {
        case 'open':
          bufferedSend(channel, message);
          break;
        case 'connecting':
          addInitQueueItem(label, message);
          break;
        case 'closing':
          log.warn(`${label} is closing. No messages accepted anymore!`);
          break;
        case 'closed':
          log.warn(`${label} is closed. Message can't be sent!`);
          break;
      }
    } else {
      addInitQueueItem(label, message);
    }
  }

  return {
    /** @type {'initiator' | 'participant'} */
    role,

    /** @type {RTCPeerConnection} */
    connection: pc,

    /**
     * @returns {ReadableStream}
     */
    getConnectionReader() {
      return new ReadableStream({
        async start(controller) {
          connReaderCtr = controller;
          if (role === 'initiator') {
            await createOffer();
          }
        },
      });
    },

    /**
     * @returns {WritableStream}
     */
    getConnectionWriter() {
      return new WritableStream({
        async write(chunk) {
          if (!isPlainObject(chunk)) return;
          const { from, data } = chunk;

          if (from) {
            state.your.id = from.id;
            state.your.name = from.name;
          }

          switch (data.type) {
            case 'offer':
              if (role === 'participant') {
                await pc.setRemoteDescription(data.offer);
                await createAnswer();
              }
              break;
            case 'answer':
              if (role === 'initiator') {
                await pc.setRemoteDescription(data.answer);
              }
              break;
            case 'candidate':
              try {
                await pc.addIceCandidate(data.candidate);
              } catch (error) {
                log.error(error);
              }
              break;
          }
        },
      });
    },

    /**
     * @param {string[]} labels
     * @returns {ReadableStream}
     */
    getFileReader(labels) {
      const listeners = new Map();

      function handleChannelSetup(
        /** @type {ReadableStreamDefaultController} */ controller,
        /** @type {RTCDataChannel} */ channel,
      ) {
        if (!channel) return;
        const label = channel.label;
        const listener = (event) => {
          const { initialized } = channels.get(label) ?? {
            initialized: false,
          };
          if (initialized) {
            controller.enqueue({ [label]: event.data });
          }
        };
        listeners.set(label, listener);
        channel.onmessage = listener;
      }

      return new ReadableStream({
        async start(controller) {
          events.addEventListener(
            'channelcreate',
            /** @type {(event: CustomEventInit<RTCDataChannel>) => void} */
            ({ detail: channel }) => {
              if (labels.includes(channel.label)) {
                handleChannelSetup(controller, channel);
                handleInitChannel(channel);
              }
            },
          );
        },
        cancel(reason) {
          log.warn(`data stream closed, because ${reason}`);
          for (const label of labels) {
            const { channel } = channels.get(label);
            const listener = listeners.get(label);
            if (channel && listener) {
              channel.removeEventListener('message', listener);
            }
          }
        },
      });
    },

    /**
     * @param {string[]} labels
     * @returns {WritableStream}
     */
    getFileWriter(labels) {
      return new WritableStream({
        write(chunk) {
          if (!isPlainObject(chunk)) return;
          for (const label of labels) {
            if (chunk[label]) send(label, chunk[label]);
          }
        },
      });
    },

    /**
     * @param {string} label
     * @returns {ReadableStream}
     */
    getMessageReader(label) {
      const listeners = new Map();

      function handleChannelSetup(
        /** @type {ReadableStreamDefaultController} */ controller,
        /** @type {RTCDataChannel} */ channel,
      ) {
        if (!channel) return;
        const label = channel.label;
        const listener = (event) => {
          const { initialized } = channels.get(label) ?? {
            initialized: false,
          };
          if (initialized) {
            controller.enqueue(event.data);
          }
        };
        listeners.set(label, listener);
        channel.onmessage = listener;
      }

      return new ReadableStream({
        async start(controller) {
          events.addEventListener(
            'channelcreate',
            /** @type {(event: CustomEventInit<RTCDataChannel>) => void} */
            ({ detail: channel }) => {
              if (label === channel.label) {
                handleChannelSetup(controller, channel);
                handleInitChannel(channel);
              }
            },
          );
        },
        cancel(reason) {
          log.warn(`data stream closed, because ${reason}`);
          const { channel } = channels.get(label);
          const listener = listeners.get(label);
          if (channel && listener) {
            channel.removeEventListener('message', listener);
          }
        },
      });
    },

    /**
     * @param {string} label
     * @returns {WritableStream}
     */
    getMessageWriter(label) {
      return new WritableStream({
        write(chunk) {
          send(label, chunk);
        },
      });
    },

    /**
     * @returns {ReadableStream}
     */
    getStateReader() {
      /**
       * @param {ReadableStreamDefaultController} controller
       * @param {string} name
       */
      function enqueueState(controller, type) {
        controller.enqueue({ type, state: pc[type] });
      }

      return new ReadableStream({
        start(controller) {
          enqueueState(controller, 'iceConnectionState');
          enqueueState(controller, 'iceGatheringState');
          enqueueState(controller, 'connectionState');
          enqueueState(controller, 'signalingState');

          pc.oniceconnectionstatechange = () =>
            enqueueState(controller, 'iceConnectionState');
          pc.onicegatheringstatechange = () =>
            enqueueState(controller, 'iceGatheringState');
          pc.onconnectionstatechange = () =>
            enqueueState(controller, 'connectionState');
          pc.onsignalingstatechange = () =>
            enqueueState(controller, 'signalingState');
        },
      });
    },
  };
}
