/// <reference path="../types/alpinejs@3.14.1.d.ts" />
/// <reference path="../types/globals.d.ts" />
import Alpine from 'https://esm.sh/alpinejs@3.14.1';
import { formatBytes } from '../core/files.js';
import log from '../core/log.js';

/**
 * Alpine.js component to manage an upload list.
 *
 * @example
 * <div x-data="uploadList" id="dropped-files">
 *   <template x-for="item in list" :key="item.hash" id="download-item-tmpl">
 *     <div class="filebox">
 *       <!-- display the download item -->
 *     </div>
 *   </template>
 * </div>
 *
 * @property {FileEntry[]} list - The list of uploads
 *
 * @function init - Initializes the component
 * @function formatBytes - Converts a number in bytes to a human-readable string
 */
export default function initUploadList() {
  Alpine.data('uploadList', () => ({
    /** @type {FileEntry[]} */
    list: [],

    get hasFiles() {
      return this.list.length > 0;
    },

    init() {
      Alpine.effect(() => {
        this.list = Array.from(Alpine.store('files').values());
      });
    },

    formatBytes() {
      const size = this.$el.dataset.size;
      log.warn('formatBytes', size);
      return formatBytes(parseInt(size, 10));
    },
  }));
}
