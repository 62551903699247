import log from '../core/log.js';
import { createSignalTransferProtocol } from '../core/streams.js';

/**
 * @typedef {Object} SignalServerProps
 * @prop {string} remoteName
 */

/**
 * @param {string} url
 * @param {import('./webrtc.js').RTCStream} peer
 * @param {import('../state.js').State} state
 * @param {SignalServerProps} [props]
 * @returns {import('../core/streams.js').SignalTransferProtocol}
 */
export function berlinSignalServer(url, peer, state) {
  const ws = new WebSocket(url);
  const queue = [];
  return createSignalTransferProtocol({
    start(controller) {
      const mir = state.my.id;
      const ick = mir;
      const pc = peer.connection;

      function stateChangeListener() {
        console.log('websocket', pc.connectionState);
        if (
          pc.connectionState === 'connected' ||
          pc.connectionState === 'disconnected' ||
          pc.connectionState === 'failed'
        ) {
          setTimeout(() => {
            log(state.my.name, 'shutting down websocket connection.');
            ws.close();
            controller.terminate();
          }, 1000);
          pc.removeEventListener('connectionstatechange', stateChangeListener);
        }
      }
      pc.onconnectionstatechange = stateChangeListener;

      ws.onopen = () => ws.send(`ICK BIN ${ick}`);
      ws.onclose = () => controller.terminate();
      ws.onmessage = (event) => {
        switch (event.data) {
          case 'JUT!':
            {
              log(`ID "${mir}" APPROVED!`);
              controller.enqueue({ type: 'id', status: 'approved' });

              if (state.your.id === null) return;
              while (queue.length) {
                const zeuch = queue.shift();
                ws.send(`ICK SACH ${zeuch} ZU ${state.your.id}`);
              }
            }
            break;
          case 'JEHT NICH!':
            {
              log(`ID "${mir}" REJECTED!`);
              controller.enqueue({ type: 'id', status: 'rejected' });
            }
            break;
          default:
            controller.enqueue(event.data);
            break;
        }
      };
    },
    transform(chunk) {
      if (ws.readyState === WebSocket.OPEN) {
        const zeug = chunk;
        ws.send(`ICK SACH ${zeug} ZU ${state.your.id}`);
      } else if (ws.readyState === WebSocket.CONNECTING) {
        queue.push(chunk);
      }
    },
  });
}
