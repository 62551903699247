import { BUS } from './bus.js';
import log from './core/log.js';

const dialogs = document.querySelectorAll('dialog');
const dialogOpener = document.querySelectorAll('[aria-haspopup="dialog"]');

export function closeAllDialogs() {
  for (const dialog of dialogs) {
    if (dialog.open) {
      close(dialog);
    }
  }
}

function close(dialog) {
  const opener = document.querySelector(
    `[aria-haspopup="dialog"][aria-controls="${dialog.id}"]`,
  );
  dialog.close();
  if (opener) setTimeout(() => opener.focus(), 0);
}

for (const dialog of dialogs) {
  dialog.onkeydown = (ev) => {
    if (dialog.open && ev.key === 'Escape') close(dialog);
  };

  const closer = dialog.querySelector('.close-dialog');

  if (closer) {
    closer.onclick = () => close(dialog);
  }
}

for (const opener of dialogOpener) {
  opener.onclick = () => {
    const controls = opener.getAttribute('aria-controls');
    const dialog = document.getElementById(controls);
    if (dialog) {
      setTimeout(() => {
        dialog.showModal();
        dialog.focus();
      }, 0);
    } else {
      log.warn(`The control element "${controls}" doesn't exist!`);
    }
  };
}

globalThis.onclick = (ev) => {
  if (!ev.target.closest('dialog')) {
    for (const dialog of dialogs) {
      if (dialog.open) {
        close(dialog);
        BUS.dispatchEvent(new Event('dialog:close'));
      }
    }
  }
};
