import * as stream from './core/streams.js';
import log from './core/log.js';
import { isPlainObject } from './core/util.js';
import { sha256 } from './core/crypto.js';

/**
 * @param {CryptoKey} secretKey
 * @param {import('./network/webrtc.js').RTCStream} peer
 * @param {import('./core/streams.js').SignalTransferProtocol} signalAPI
 */
export async function peerConnect(secretKey, peer, signalAPI) {
  await peer
    .getConnectionReader()

    // connect to Signal Server
    .pipeThrough(stream.encodeJSON())
    .pipeThrough(stream.addOrderNumber((chunk, index) => `${index}:${chunk}`))
    .pipeThrough(stream.encrypt(secretKey, { base64Enabled: true }))
    .pipeThrough(signalAPI)
    .pipeThrough(
      stream.through((chunk, controller) => {
        if (isPlainObject(chunk)) return;
        controller.enqueue(chunk);
      }),
    )
    .pipeThrough(stream.decrypt(secretKey, { base64Enabled: true }))
    .pipeThrough(
      stream.orderBy((chunk) => {
        const i = chunk.indexOf(':');
        return {
          index: parseInt(chunk.slice(0, i), 10),
          data: chunk.slice(i + 1),
        };
      }),
    )
    .pipeThrough(stream.decodeJSON())

    // connect with peer over WebRTC
    .pipeThrough(
      log.stream((chunk) => log('initiator->participant', chunk.data)),
    )
    .pipeTo(peer.getConnectionWriter());
}

/**
 * @param {CryptoKey} secretKey
 * @param {import('./network/webrtc.js').RTCStream} peer
 * @param {(chunk: any) => void} [onReceive]
 */
export function fileTransmitter(secretKey, peer, onReceive) {
  if (!onReceive) {
    onReceive = (chunk) => log(new TextDecoder().decode(chunk));
  }

  const fromChunks = stream.fromChunks();
  const toChunks = stream.toChunks();

  peer
    .getFileReader(['metadata', 'chunk'])
    .pipeThrough(stream.decryptJSON(secretKey, { props: ['metadata'] }))
    .pipeThrough(fromChunks.transform)
    .pipeThrough(stream.decrypt(secretKey))
    .pipeTo(stream.writer(onReceive));

  return {
    send(/** @type {File | Blob | ArrayBuffer | Uint8Array | string} */ data) {
      try {
        return stream
          .reader(async (controller) => {
            if ('string' === typeof data) {
              data = new TextEncoder().encode(data);
            } else if (data instanceof File) {
              data = await data.arrayBuffer();
            } else if (data instanceof Blob) {
              data = await data.arrayBuffer();
            }
            controller.enqueue(data);
            controller.close();
          })
          .pipeThrough(
            // TODO: move that somewhere else(?)
            stream.through(async (chunk, controller) => {
              const decryptedHash = await sha256(chunk);
              controller.enqueue({ metadata: { decryptedHash }, chunk });
            }),
          )
          .pipeThrough(stream.encryptJSON(secretKey, { props: ['chunk'] }))
          .pipeThrough(toChunks.transform)
          .pipeThrough(stream.encryptJSON(secretKey, { props: ['metadata'] }))
          .pipeTo(peer.getFileWriter(['metadata', 'chunk']));
      } catch (error) {
        log.error(error);
      }
    },
    sentChunks() {
      return toChunks.getChunkCounter();
    },
    receivedChunks() {
      return fromChunks.getChunkCounter();
    },
  };
}

/**
 * @param {CryptoKey} secretKey
 * @param {import('./network/webrtc.js').RTCStream} peer
 * @param {(chunk: any) => void} [onReceive]
 */
export function messageTransmitter(secretKey, peer, onReceive) {
  if (!onReceive) {
    onReceive = (chunk) => log(chunk);
  }
  peer
    .getMessageReader('message')
    .pipeThrough(stream.decrypt(secretKey))
    .pipeThrough(stream.decodeJSON())
    .pipeTo(stream.writer(onReceive));

  return (/** @type {string | object} */ data) => {
    stream
      .reader((controller) => controller.enqueue(data), true)
      .pipeThrough(stream.encodeJSON())
      .pipeThrough(stream.encrypt(secretKey))
      .pipeTo(peer.getMessageWriter('message'));
  };
}
