/// <reference path="../types/alpinejs@3.14.1.d.ts" />
/// <reference path="../types/globals.d.ts" />
import Alpine from 'https://esm.sh/alpinejs@3.14.1';
import { BUS } from '../bus.js';
import { formatBytes } from '../core/files.js';
import log from '../core/log.js';

/**
 * A Alpine.js component to manage a list of downloads.
 *
 * @example
 * <div x-data="downloadList" id="dropped-files">
 *   <template x-for="item in list" :key="item.hash" id="download-item-tmpl">
 *     <div class="filebox">
 *       <!-- display the download item -->
 *     </div>
 *   </template>
 * </div>
 *
 * @property {FileEntry[]} list - The list of downloads
 *
 * @function init - Initializes the component
 * @function fetch - Fetches the files from download source
 * @function fetchAll - Fetches all files from download source
 * @function save - Saves a file on hard disk
 * @function saveAll - Saves all files on hard disk
 * @function remove - Removes the download
 * @function removeAll - Removes all downloads
 */
export default function initDownloadList() {
  Alpine.data('downloadList', () => ({
    /** @type {FileEntry[]} */
    list: [],

    get hasFiles() {
      return this.list.length > 0;
    },

    init() {
      Alpine.effect(() => {
        this.list = Array.from(Alpine.store('downloadList').values());
      });
    },

    formatBytes() {
      const size = this.$el.dataset.size;
      log.warn('formatBytes', size);
      return formatBytes(parseInt(size, 10));
    },

    fetch() {
      const hash = this.$el.dataset.hash;
      if (hash) {
        BUS.dispatchEvent(
          new CustomEvent('request:file', {
            detail: {
              files: [hash],
              reload: false,
            },
          }),
        );
      }
    },

    fetchAll() {
      BUS.dispatchEvent(
        new CustomEvent('request:files', {
          detail: {
            files: 'all',
            reload: false,
          },
        }),
      );
    },

    save() {
      // TODO: not yet implemented
    },

    saveAll() {
      // TODO: not yet implemented
    },

    remove() {
      const hash = this.$el.dataset.hash;
      if (hash) {
        Alpine.store('downloadList').delete(hash);
      }
    },

    removeAll() {
      Alpine.store('downloadList').clear();
    },
  }));

  Alpine.store('downloadList', new Map());
}
